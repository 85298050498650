<template>
  <b-container fluid>
    <form>
      <b-row>
        <b-col lg="12">
          <iq-card body-class="p-0">
            <template v-slot:body>
              <div class="iq-edit-list">
                <tab-nav :pills="true" class="iq-edit-profile d-flex">
                  <tab-nav-items class="col-md-3 p-0" :active="true" href="#personal-information"
                    title="Personal Information" />
                  <tab-nav-items class="col-md-3 p-0" :active="false" href="#consultation-notes"
                    title="Consultation Notes" />
                  <tab-nav-items class="col-md-3 p-0" :active="false" href="#prescriptions" title="Prescriptions" />
                  <tab-nav-items class="col-md-3 p-0" :active="false" href="#sick-notes" title="Sick notes" />
                  <tab-nav-items class="col-md-3 p-0" :active="false" href="#referral-notes" title="Referral notes" />
                </tab-nav>
              </div>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="12">
          <div class="iq-edit-list-data">
            <tab-content id="pills-tabContent-2">
              <tab-content-item :active="true" id="personal-information">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Personal Information</h4>
                  </template>
                  <template v-slot:body>
                    <!-- <b-form-group class="row align-items-center">
                      <b-col md="12">
                        <div class="profile-img-edit">
                          <b-img v-if="user.profileImage" :src="user.profileImage"
                            class="profile-pic height-150 width-150" alt="profile-pic" />
                          <b-img v-else src="../../assets/images/user/1.jpg" class="profile-pic height-150 width-150"
                            alt="profile-pic" />
                          <input type="hidden" v-model="user.profileImage">
                          <div class="p-image">
                            <div class="position-relative">
                              <i class="ri-pencil-line upload-button"></i>
                              <b-form-file class="h-100 position-absolute" accept="image/*" style="opacity: 0;"
                                @change="previewImage"></b-form-file>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-form-group> -->
                    <!-- <b-form-group class="col-sm-12" label="Bio:">
                      <b-form-textarea name="bio" v-model="user.bio" style="line-height: 22px;" rows="5">
                      </b-form-textarea>
                    </b-form-group> -->

                    <b-row align-v="center">
                      <div class="col-md-12 my-3">
                        <h4>Patient Info:</h4>
                      </div>
                      <b-form-group class="col-md-6" label="First Name" label-for="first_name">
                        <ValidationProvider name="First name" rules="required" v-slot="{ errors }">
                          <b-form-input v-model="user.first_name" type="text" placeholder="First Name"
                            :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>

                      <b-form-group class="col-md-6" label="Last Name" label-for="last_name">
                        <ValidationProvider name="Last name" rules="required" v-slot="{ errors }">
                          <b-form-input v-model="user.last_name" type="text" placeholder="Last Name"
                            :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>

                      <b-form-group class="col-md-6" label="Email" label-for="email">
                        <ValidationProvider name="Patient email" rules="required" v-slot="{ errors }">
                          <b-form-input v-model="user.email" type="text" placeholder="Email"
                            :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>

                      <b-form-group class="col-md-6" label="Patient I.D" label-for="patient_id">
                        <ValidationProvider name="Patient I.D" rules="required" v-slot="{ errors }">
                          <b-form-input v-model="user.patient_id" type="text" placeholder="I.D"
                            :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>

                      <b-form-group class="col-md-6" label="Patient Date of Birth" label-for="patient_age">
                        <ValidationProvider name="Patient DOB" rules="required" v-slot="{ errors }">
                          <b-form-input v-model="user.patient_age" type="text" placeholder="Day-Month-Year eg 01-02-1990"
                            :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>

                      <b-form-group class="col-md-6" label="Phone" label-for="phone_number">
                        <ValidationProvider name="User name" rules="required" v-slot="{ errors }">
                          <b-form-input v-model="user.phone_number" type="text" placeholder="Phone"
                            :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>

                      <b-form-group class="col-md-6" label="Patient ID Number" label-for="id_number">
                        <ValidationProvider name="ID Number" rules="required" v-slot="{ errors }">
                          <b-form-input v-model="user.id_number" type="text" placeholder="Patient ID Number"
                                        :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                      <b-form-group class="col-md-6" label="Patient sign-up date" label-for="sign_up_date">
                        <ValidationProvider name="Patient Sign-up date" rules="required" v-slot="{ errors }">
                          <b-form-input :value="showSignUpDate()" type="text" placeholder="Patient sign-up date"
                                        :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>

                      <div class="col-md-12  my-3">
                        <h4>Address Info:</h4>
                      </div>
                      <b-form-group class="col-md-12" label="Address" label-for="address">
                        <b-form-input v-model="user.address" type="text" placeholder="address"></b-form-input>
                      </b-form-group>
                      <b-form-group class="col-md-6" label="City" label-for="city">
                        <b-form-input v-model="user.city" type="text" placeholder="City"></b-form-input>
                      </b-form-group>
                      <b-form-group class="col-md-6" label="Postal code" label-for="postal_code">
                        <b-form-input v-model="user.postal_code" type="text" placeholder="postal_code"></b-form-input>
                      </b-form-group>
                      <div class="col-md-12  my-3">
                        <h4>Account Status:</h4>
                      </div>
                      <b-form-group class="col-sm-8" label-for="profile_status" label="Profile activation status">
                        <b-form-select plain v-model="user.profile_status" :options="profile_status">
                        </b-form-select>
                      </b-form-group>
                      <b-form-group class="col-sm-4" label-for="profile_status" label="Set Status">
                        <b-button type="button" variant="success" class="mr-2" @click="handleUpdateProfile">Update Profile Status</b-button>
                      </b-form-group>
                    </b-row>
                    <b-row>
                      <b-form-group class="col-sm-2 " label-for="corporate_client" label="Corporate Client">
                        <b-form-input disabled class="form-control-sm" :value="user.corporate_client" type="text" placeholder="Corporate Client"></b-form-input>
                      </b-form-group>
                    <b-form-group class="col-sm-3" label-for="corporate_client_name" label="Corporate Account Name">
                      <b-form-input readonly class="form-control-sm" v-model="user.company_name" type="text" placeholder="None"></b-form-input>
                    </b-form-group>
                    <b-form-group class="col-sm-3" label-for="corporate_client_status" label="Corporate Account Verified">
                      <b-form-select class="form-control-lg" v-model="user.corp_verified" :options="corporate_client">
                         </b-form-select>
                    </b-form-group>
                    <b-form-group class="col-sm-3" label-for="corporate_client_verified" label="Set Status">
                      <b-button  type="button" variant="success"  class="mr-2" @click="handleCorporateUpdate">Update Corporate Status</b-button>
                    </b-form-group>
                    </b-row>

                    <b-button type="submit" variant="primary" class="mr-2" @click="handleUpdate">Submit</b-button>
                    <b-button type="reset" variant="none" class="iq-bg-danger">Cancel</b-button>
                  </template>
                </iq-card>
              </tab-content-item>

              <tab-content-item :active="false" id="consultation-notes">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Issued Consultation Notes</h4>
                  </template>
                  <template v-slot:body>
                    <table class="table mb-0 table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Date issued</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in medical_documents.consultNotes" :key="item.mr_id">
                          <td class="col-md-3"> {{item.provider_name}}</td>
                          <td class="col-md-3">
                            {{item.date}}
                          </td>
                          <td class="col-md-3">
                            {{item.medical_record}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                </iq-card>
              </tab-content-item>

              <tab-content-item :active="false" id="prescriptions">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Issued Prescriptions</h4>
                  </template>
                  <template v-slot:body>
                    <table class="table mb-0 table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Date issued</th>
                          <th scope="col">Phone</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in medical_documents.prescriptions" :key="item.mr_id">
                          <td> {{item.providerName}}</td>
                          <td>
                            {{item.date}}
                          </td>
                          <td>
                            <div class="flex">
                              <b-button :href="item.pdfUrl" block variant="primary">Download</b-button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </template>
                </iq-card>
              </tab-content-item>

              <tab-content-item :active="false" id="sick-notes">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Issued Sick Notes</h4>
                  </template>
                  <template v-slot:body>
                    <table class="table mb-0 table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Date issued</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr  v-for="item in medical_documents.medicalCertificates" :key="item.mr_id">
                          <td> {{item.providerName}}</td>
                          <td>
                            {{item.date}}
                          </td>
                          <td>
                            <div class="flex">
                              <b-button :href="item.pdfUrl" block variant="primary">Download</b-button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </template>
                </iq-card>
              </tab-content-item>

              <tab-content-item :active="false" id="referral-notes">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Issued Referral Notes</h4>
                  </template>
                  <template v-slot:body>
                    <table class="table mb-0 table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Date issued</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                      <tr  v-for="item in medical_documents.referralNotes" :key="item.mr_id">
                        <td> {{item.providerName}}</td>
                        <td>
                          {{item.date}}
                        </td>
                        <td>
                          <div class="flex">
                            <b-button :href="item.pdfUrl" block variant="primary">Download</b-button>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>

                  </template>
                </iq-card>
              </tab-content-item>
            </tab-content>
          </div>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
<script>
import { xray } from '@config/pluginInit'
import apiClient from '@config/apiClient'
import PatientService from '../../services/patient'
import Vue from 'vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import moment from 'moment'
Vue.use(Loading)
export default {
  name: 'Patient-profile',
  mounted () {
    xray.index()
    const loader = this.showLoader()
    apiClient.post('admin/get_patient_account', {
      patient_id: this.$route.params.id
    }).then(response => {
      loader.hide()
      this.user = response.data
      apiClient.get(`admin/get_all_documents/${this.$route.params.id}`).then(response => {
        loader.hide()
        this.medical_documents = response.data
      }).catch(e => {
        loader.hide()
      })
    }).catch(e => {
      loader.hide()
    })
  },
  data () {
    return {
      user: {},
      medical_documents: {},
      currentPassword: '',
      medical_aid_status: [
        { value: 0, text: 'In-active' },
        { value: 1, text: 'Active' }
      ],
      profile_status: [
        { value: 0, text: 'In-active' },
        { value: 1, text: 'Active' }
      ],
      corporate_client: [
        { value: 0, text: 'In-active' },
        { value: 1, text: 'Active' }
      ]
    }
  },
  methods: {
    previewImage: function (event) {
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()

        reader.onload = (e) => {
          this.user.profile_image = e.target.result
        }

        reader.readAsDataURL(input.files[0])
      }
    },
    changePassword: function () {
    },
    showSignUpDate () {
      return moment(this.user.created_at).format('YYYY-MM-DD HH:mm')
    },
    showLoader () {
      return this.$loading.show({
        isFullPage: true,
        canCancel: true,
        onCancel: this.onCancel
      })
    },
    handleCorporateUpdate: function () {
      const loader = this.showLoader()
      let payload = {
        patient_id: parseInt(this.$route.params.id),
        corp_verified: this.user.corp_verified
      }
      PatientService.update_patient_corp_status(payload)
        .then((response) => {
          console.log('patient corp status update response : ', response)
          loader.hide()
        })
        .catch((error) => {
          console.log('patient corp status update error : ', error)
          loader.hide()
        })
    },
    handleUpdateProfile: function () {
      const loader = this.showLoader()
      let payload = {
        patient_id: parseInt(this.$route.params.id),
        profile_status: parseInt(this.user.profile_status)
      }
      PatientService.update_patient_profile_status(payload)
        .then((response) => {
          console.log('patient service response : ', response)
          loader.hide()
        })
        .catch((error) => {
          console.log('patient status update error : ', error)
          loader.hide()
        })
    },
    handleUpdate: function () {
      console.log(this.user, 'test')
      const loader = this.showLoader()
      apiClient.patch('admin/update_patient_account', {
        patient_id: this.$route.params.id,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        phone_number: this.user.phone_number,
        age: this.user.patient_age,
        city: this.user.city,
        address: this.user.address,
        postal_code: this.user.postal_code,
        profile_status: this.user.profile_status,
        medical_aid_status: this.user.medical_aid_status,
        corporate_account_status: this.user.corporate_client,
        id_number: this.user.id_number
      }).then(response => {
        loader.hide()
        console.log('>>>', response)
      }).catch(e => {
        console.log('>>> errror')
        loader.hide()
      })
    }
  }
}
</script>
