<template>
  <div class="iq-card">
    <div class="iq-card-body">
      <div class="row align-items-center">
        <div class="col-md-12 mb-3">
          <h5 class="text-primary float-left"><i class="ri-pencil-fill"></i>Generate Voucher Codes</h5>
          <button type="submit" class="float-right close-popup" v-if="modal" @click="close" data-dismiss="modal"><i
              class="ri-close-fill"></i></button>
        </div>
      </div>
      <form class="email-form">
        <div class="form-group row">
          <label for="subject" class="col-sm-2 col-form-label">Voucher Type:</label>
          <div class="col-sm-10">
            <select  v-model="voucherType" class="form-control">
              <option value="1">Doctors Consult</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label for="subject" class="col-sm-2 col-form-label">No. Of Vouchers:</label>
          <div class="col-sm-10">
            <select  v-model="numberOfVouchers" class="form-control">
              <option value="1">1</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </select>
          </div>
        </div>

        <div class="form-group row align-items-center pt-3 m-0">
          <div class="d-flex flex-grow-1 align-items-center">
            <div class="send-btn">
              <button type="submit" :disabled="!numberOfVouchers && !voucherType" @click.prevent="generateVouchers()" class="btn btn-primary">Submit</button>
            </div>
          </div>

        </div>
      </form>
    </div>
  </div>
</template>
<script>

import VoucherService from '../../services/vouchers'

export default {
  name: 'AddVoucher',
  props: {
    modal: { type: Boolean, default: false }
  },
  data () {
    return {
      numberOfVouchers: 1,
      voucherType: 1
    }
  },
  methods: {
    successToast () {
      this.$bvToast.toast('Voucher code generated', {
        title: `Voucher Created`,
        variant: 'success',
        solid: true
      })
    },
    errorToast () {
      this.$bvToast.toast('Error Voucher code generated', {
        title: `Error Voucher Created`,
        variant: 'danger',
        solid: true
      })
    },
    showLoader () {
      return this.$loading.show({
        isFullPage: true,
        canCancel: true,
        onCancel: this.onCancel
      })
    },
    generateVouchers () {
      if (this.numberOfVouchers && this.voucherType) {
        const loader = this.showLoader()
        VoucherService.generate_multiple_vouchers(this.numberOfVouchers, this.voucherType)
          .then((response) => {
            console.log('response : ', response)
            this.close()
            loader.hide()
            this.successToast()
          })
          .catch((error) => {
            console.log('error : ', error)
            loader.hide()
            this.errorToast()
          })
      }
    },
    close () {
      this.$emit('close', false)
    }
  }
}
</script>
