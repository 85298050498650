<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <b-row>
          <b-col sm="12">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="">
              <template v-slot:headerTitle>
                <h4 class="card-title">Patients</h4>
              </template>
              <template v-slot:body>
                <div >
                  <template>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                    ></v-text-field>
                    <v-data-table
                      :headers="headers"
                      :items="patients"
                      :search="search"
                    >
                      <template v-slot:item.actions="{ item }">
                        <div class="flex">
                          <b-button variant="outline-primary" class="mr-2"  @click="() => navigateToPatientProfile(item.patient_id)"><i class="ri-information-line"></i> Details</b-button>
                          <b-button @click="showModal(item.patient_id, item.first_name)"  variant="outline-danger" class="mr-2"><i class="ri-delete-bin-line"></i> Delete</b-button>
                        </div>
                      </template>
                      <template v-slot:item.profile_status="{ item }">
                        <div class="flex">
                          <b-badge :class="['border mr-1', item.profile_status === 1 ? 'border-success text-success' : 'border-danger text-danger']" variant="none">{{ PATIENT_STATUS_MAPPED.get(item.profile_status) }}</b-badge>
                        </div>
                      </template>
                    </v-data-table>
                  </template>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
      <b-modal ref="delete-confirmation" size="sm" hide-footer title="Delete user">
        <p>Please confirm if you would like to delete the Patient <b>{{ selectedPatient.name }}</b></p>
        <b-button class="mt-3" variant="outline-primary" block @click="cancelDeletion">Cancel</b-button>

        <b-overlay
          :show="deleting"
          rounded="lg"
          opacity="0.2"
          spinner-small
          spinner-variant="danger"
          @hidden="onHidden"
          bg-color="red"
        >
          <b-button class="mt-3" variant="outline-danger" block @click="confirmDeletion">Confirm</b-button>
        </b-overlay>
      </b-modal>
  </b-container>
</template>
<script>
import { xray } from '@config/pluginInit'
import IqCard from '@components/xray/cards/iq-card'
import { PATIENT_STATUS_MAPPED } from '@constants/patient'
import PatientService from '@services/patient'
import Vue from 'vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.use(Loading)

export default {
  name: 'Patients',
  components: { IqCard },

  data () {
    return {
      patients: [],
      PATIENT_STATUS_MAPPED,
      selectedPatient: {
        name: '',
        patient_id: ''
      },
      deleting: false,
      search: '',
      headers: [
        {
          text: 'First Name',
          align: 'start',
          value: 'first_name'
        },
        {
          text: 'Last Name',
          align: 'start',
          value: 'last_name'
        },
        { text: 'Age', value: 'patient_age' },
        { text: 'Phone', value: 'phone_number' },
        { text: 'Email', value: 'email' },
        { text: 'City', value: 'city' },
        { text: 'Status', value: 'profile_status' },
        { text: 'Actions', value: 'actions' }
      ]
    }
  },
  mounted () {
    xray.index()
    const loader = this.showLoader()
    PatientService.get_all_patient_accounts()
      .then((response) => {
        this.patients = response.data
        loader.hide()
      })
      .catch((error) => {
        console.error('error : ', error)
        loader.hide()
      })
      .finally(() => {
        console.log('execute finally')
        loader.hide()
      })
  },

  methods: {
    navigateToPatientProfile (id) {
      this.$router.push(`patients/profile/${id}`)
    },
    showLoader () {
      return this.$loading.show({
        isFullPage: true,
        canCancel: true,
        onCancel: this.onCancel
      })
    },
    showModal (id, name) {
      this.$refs['delete-confirmation'].show()
      this.selectedPatient.name = name
      this.selectedPatient.patient_id = id
    },
    cancelDeletion () {
      this.$refs['delete-confirmation'].hide()
      this.selectedPatient.name = ''
      this.selectedPatient.patient_id = ''
    },
    removePatient () {
      const indexToDelete = this.patients.findIndex(patient => patient.patient_id === this.selectedPatient.patient_id)

      if (indexToDelete !== -1) {
        this.patients.splice(indexToDelete, 1)
      }
    },
    onHidden () {
      this.$refs.button.focus()
    },
    confirmDeletion () {
      this.deleting = true
      PatientService.delete_patient(this.selectedPatient.patient_id)
        .then(() => {
          this.$refs['delete-confirmation'].hide()
          this.removePatient()
          this.deleting = false
          this.selectedPatient.name = ''
          this.selectedPatient.patient_id = ''
        })
        .catch((error) => {
          console.error('error deleting patient: ', error)
        })
        .finally(() => {
          console.log('executed finally ')
        })
    }
  }
}
</script>

<style>
.iq-card-body{
  flex: unset;
}
</style>
