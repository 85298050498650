<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <b-row>
          <b-col sm="12">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="">
              <template v-slot:headerTitle>
                <h4 class="card-title">Booking History</h4>
              </template>
              <template v-slot:headerAction>
              </template>
              <template v-slot:body>
                <div class="table-responsive">
                  <table class="table mb-0 table-borderless">
                    <thead>
                    <tr>
                      <th scope="col">Patient</th>
                      <th scope="col">Provider</th>
                      <th scope="col">Booking Date</th>
                      <th scope="col">Booking Slot </th>
                      <th scope="col">Booking Status </th>
                      <th scope="col">Booking Rate </th>
                      <th scope="col">Payment Method</th>
                      <th scope="col">Paid Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in history" :key="'atr_'+index">
                      <td>{{ item.patient_name ? item.patient_name +' '+ item.patient_surname : 'None'}} {{  }}</td>
                      <td>{{ item.provider_accounts?.first_name }} {{ item.provider_accounts?.last_name }}</td>
                      <td>{{ format(new Date(item?.slot_date), 'MM/dd/yyyy') }}</td>
                      <td>{{ item?.slot_time }}</td>
                      <td>
                        <b-badge class="border mr-1 border-success text-success" variant="none">{{ item?.booking_status }}</b-badge>
                      </td>
                      <td>R{{ formatCurrency(Number(item?.booking_rate)) }}</td>
                      <td>{{ item?.payment_method }}</td>
                      <td  v-if="item?.booking_paid === 1">Paid</td>
                      <td v-if="item?.booking_paid === 0"><b-button type="button" variant="primary" class="mr-2" @click="updateUnpaidStatus(item?.id)">Mark as paid</b-button></td>
                    </tr>

                    </tbody>
                  </table>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { format } from 'date-fns'
import { xray } from '../../config/pluginInit'
import IqCard from '../../components/xray/cards/iq-card'
import AdminService from '../../services/admin'
import Vue from 'vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.use(Loading)

export default {
  name: 'Booking-history',
  components: { IqCard },
  mounted () {
    xray.index()
    this.getBookingAppointments()
  },
  data () {
    return {
      history: []
    }
  },
  methods: {
    format,
    showLoader () {
      return this.$loading.show({
        isFullPage: true,
        canCancel: true,
        onCancel: this.onCancel
      })
    },
    getBookingAppointments () {
      const loader = this.showLoader()
      AdminService.get_appointment_history().then(({ data }) => {
        this.history = data
        loader.hide()
      }).catch((error) => {
        console.error('error: ', error)
        loader.hide()
      })
    },
    updateUnpaidStatus (id) {
      const loader = this.showLoader()
      let payload = {
        booking_id: id
      }
      AdminService.update_unpaid_status(payload).then(({ data }) => {
        loader.hide()
        this.getBookingAppointments()
      }).catch((error) => {
        console.error('error: ', error)
        loader.hide()
      })
    },
    formatCurrency (amount) {
      if (typeof amount !== 'number' || isNaN(amount)) {
        return 'Invalid Amount'
      }
      return amount.toFixed(2)
    }
  }
}
</script>
<style>
.iq-card-body{
  flex: unset;
}
</style>
