<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <b-row>
          <b-col sm="12">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="">
              <template v-slot:headerTitle>
                <h4 class="card-title">Voucher List</h4>
              </template>
              <template v-slot:headerAction>
                <tab-nav :pills="true" id="voucher-tab" class="mt-1 mr-5">
                  <tab-nav-items :active="true" id="active-vouchers-tab" ariaControls="active" role="tab" :ariaSelected="true" title="Active" />
                  <tab-nav-items :active="false" id="used-vouchers-tab" ariaControls="used" role="tab" :ariaSelected="false" title="Used" />
                </tab-nav>
                <b-button  @click.prevent="showGenerateVoucherModal()" variant="warning" class="mt1">Generate Voucher Codes</b-button>
              </template>
              <template v-slot:body>
                <tab-content id="vouche-content">
                  <tab-content-item :active="true" id="active" aria-labelled-by="active-vouchers-tab">
                    <div>
                      <template>
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                        ></v-text-field>
                        <v-data-table
                          :headers="headers"
                          :items="voucherList"
                          :search="search"
                        >
                          <template v-slot:item.voucher_status="{ item }">
                            <b-tag variant="success">{{ item.voucher_status == 1 ? 'Available' : 'Used' }}</b-tag>
                          </template>
                          <template v-slot:item.updated_at="{ item }">
                            <div class="flex">
                              <p v-if="item.voucher_status === 1">{{ $date(item.updated_at).format('MMM D, YYYY')   }}</p>
                            </div>
                          </template>
                        </v-data-table>
                      </template>
                    </div>
                  </tab-content-item>
                  <tab-content-item :active="false" id="used" aria-labelled-by="used-vouchers-tab">
                    <div>
                      <template>
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                        ></v-text-field>
                        <v-data-table
                          :headers="headers"
                          :items="inActiveVoucherList"
                          :search="search"
                        >
                          <template v-slot:item.voucher_status="{ item }">
                            <b-tag variant="danger">{{ item.voucher_status == 1 ? 'Available' : 'Used' }}</b-tag>
                          </template>
                          <template v-slot:item.updated_at="{ item }">
                            <div class="flex">
                              <p v-if="item.voucher_status === 1">{{ $date(item.updated_at).format('MMM D, YYYY')   }}</p>
                            </div>
                          </template>
                        </v-data-table>
                      </template>
                    </div>

                  </tab-content-item>
                </tab-content>
              </template>
            </iq-card>
          </b-col>
          <b-modal v-model="show" id="compose-email-popup" size="lg" hide-footer hide-header content-class="border-0" dialog-class="m-0" static body-class="p-0" class="compose-popup modal-sticky-bottom-right modal-sticky-lg" cancel-title="Close">
      <AddVoucher class="mb-0" :modal="true" @close="closeModal"/>
      </b-modal>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import IqCard from '../../components/xray/cards/iq-card'
import VoucherService from '../../services/vouchers'
import Vue from 'vue'
import Loading from 'vue-loading-overlay'
import AddVoucher from './AddVoucher.vue'
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.use(Loading)

export default {
  name: 'Doctors',
  components: { AddVoucher, IqCard },
  mounted () {
    xray.index()
    this.listZltoVouchers()
  },
  data () {
    return {
      show: false,
      voucherList: [],
      inActiveVoucherList: [],
      search: '',
      headers: [
        {
          text: 'Voucher ID',
          align: 'start',
          value: 'voucher_id'
        },
        {
          text: 'Voucher Code',
          align: 'start',
          value: 'voucher_code'
        },
        { text: 'Doctor', value: 'provider_full_name' },
        { text: 'Patient', value: 'patient_full_name' },
        { text: 'Status', value: 'voucher_status' },
        { text: 'Last Update', value: 'updated_at' }
      ]
    }
  },
  methods: {
    listZltoVouchers () {
      const loader = this.showLoader()
      VoucherService.list_all_vouchers()
        .then((voucherResponse) => {
          this.voucherList = voucherResponse.data.filter(item => item.voucher_status !== 0)
          this.inActiveVoucherList = voucherResponse.data.filter(item => item.voucher_status === 0)
          loader.hide()
        })
        .catch((error) => {
          console.log('voucher list error : ', error)
          loader.hide()
        })
    },
    successToast () {
      this.$bvToast.toast('Voucher code generated', {
        title: `Voucher Created`,
        variant: 'success',
        solid: true
      })
    },
    errorToast () {
      this.$bvToast.toast('Error Voucher code generated', {
        title: `Error Voucher Created`,
        variant: 'danger',
        solid: true
      })
    },
    showLoader () {
      return this.$loading.show({
        isFullPage: true,
        canCancel: true,
        onCancel: this.onCancel
      })
    },
    showGenerateVoucherModal () {
      this.show = true
    },
    closeModal (value) {
      this.show = value
      this.listZltoVouchers()
    }
  }
}
</script>

<style>
.iq-card-body{
  flex: unset;
}
</style>
