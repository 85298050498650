<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <b-row>
          <b-col sm="12">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="">
              <template v-slot:headerTitle>
                <h4 class="card-title">Charges List</h4>
              </template>
              <template v-slot:headerAction>
                <b-button variant="primary" @click="showAddModal">Add Charge</b-button>
              </template>
              <template v-slot:body>
                <div class="table-responsive w-100">
                  <table class="table mb-0 table-borderless">
                    <thead>
                    <tr>
                      <th scope="col">S.No.</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Created At</th>
                      <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(charge, index) in charge_list"  :key="charge.id">
                      <td>{{ index + 1 }}</td>
                      <td>
                        {{ charge.amount }}
                      </td>
                      <td>
                        {{ $date(charge.created_at).format('MMM D, YYYY') }}
                      </td>
                      <td>
                        <b-button variant="outline-danger" @click="showDeleteConfirmation(charge.id, charge.amount)" class="mr-2"><i class="ri-delete-bin-line"></i> Delete</b-button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-modal ref="delete-confirmation" size="sm" hide-footer title="Delete user">
      <p>Please confirm if you would like to delete the the fee <b>{{ selectedCharge.amount }}</b></p>
      <b-button class="mt-3" variant="outline-primary" block @click="cancelDeletion">Cancel</b-button>

      <b-overlay
        :show="deleting"
        rounded="lg"
        opacity="0.2"
        spinner-small
        spinner-variant="danger"
        @hidden="onHidden"
        bg-color="red"
      >
        <b-button class="mt-3" variant="outline-danger" block @click="confirmDeletion">Confirm</b-button>
      </b-overlay>
    </b-modal>

    <b-modal ref="add-charge" size="sm" hide-footer title="Add Charge Fee">
      <b-form-input type="number" :value="charge_fee"></b-form-input>
      <b-button class="mt-3" variant="outline-danger" block @click="cancelAdd">Cancel</b-button>
      <b-overlay
        :show="saving"
        rounded="lg"
        opacity="0.2"
        spinner-small
        spinner-variant="danger"
        @hidden="onHidden"
        bg-color="red"
      >
        <b-button class="mt-3" variant="outline-primary" block @click="confirmSave">Save</b-button>
      </b-overlay>
    </b-modal>
  </b-container>
</template>
<script>
import { xray } from '@config/pluginInit'
import IqCard from '@components/xray/cards/iq-card'
import AdminService from '@services/admin'
import Vue from 'vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.use(Loading)

export default {
  name: 'charges-list',
  components: { IqCard },
  data () {
    return {
      charge_list: [],
      charge_fee: 0,
      deleting: false,
      saving: false,
      selectedCharge: {
        id: null,
        amount: ''
      }
    }
  },
  mounted () {
    xray.index()
    const loader = this.showLoader()
    AdminService.get_all_charge_options()
      .then((response) => {
        this.charge_list = response.data
        loader.hide()
      })
      .catch((error) => {
        console.error('error: ', error)
        loader.hide()
      })
      .finally(() => {
        console.log('Executed')
        loader.hide()
      })
  },
  methods: {
    showLoader () {
      return this.$loading.show({
        isFullPage: true,
        canCancel: true,
        onCancel: this.onCancel
      })
    },
    showAddModal () {
      this.$refs['add-charge'].show()
    },
    showDeleteConfirmation (id, amount) {
      this.$refs['delete-confirmation'].show()
      this.selectedCharge.id = id
      this.selectedCharge.amount = amount
    },
    onHidden () {
      this.$refs.button.focus()
    },
    confirmSave () {

    },
    confirmDeletion () {

    },
    cancelDeletion () {
      this.$refs['delete-confirmation'].hide()
      this.selectedCharge.id = ''
      this.selectedCharge.amount = ''
    },
    cancelAdd () {
      this.$refs['add-charge'].hide()
    }
  }
}
</script>

<style>
.iq-card-body{
  flex: unset;
}
</style>
