import axios from 'axios'
import store from '../store/index'

const apiClient = axios.create({
  baseURL: 'https://web-api.mypockethealth.co.za/',
  // baseURL: 'http://127.0.0.1:3000/',
  timeout: 15000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

apiClient.interceptors.request.use((config) => {
  const userToken = store.getters.getToken
  config.headers.Authorization = `Bearer ${userToken}`
  config.headers.Accept = 'application/json'
  return config
})
export default apiClient
