<template>
  <b-container fluid>
    <form>
      <b-row>
        <b-col lg="12">
          <iq-card body-class="p-0">
            <template v-slot:body>
              <div class="iq-edit-list">
                <tab-nav :pills="true" class="iq-edit-profile d-flex">
                  <tab-nav-items class="col-md-4 p-0" :active="true" href="#personal-information" title="Personal Information" />
                  <tab-nav-items class="col-md-4 p-0" :active="false" href="#manage-documents" title="Documents" />
                  <tab-nav-items class="col-md-4 p-0" :active="false" href="#manage-password" title="Password" />
                </tab-nav>
              </div>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="12">
          <div class="iq-edit-list-data">
            <tab-content id="pills-tabContent-2">
              <tab-content-item :active="true" id="personal-information" >
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Personal Information</h4>
                  </template>
                  <template v-slot:body>
                      <b-form-group class="row align-items-center">
                        <b-col md="12">
                          <div class="profile-img-edit">
                            <b-img v-if="user.profileImage"  :src="user.profileImage" class="profile-pic height-150 width-150" alt="profile-pic"/>
                            <b-img v-else src="../../assets/images/user/1.jpg" class="profile-pic height-150 width-150" alt="profile-pic"/>
                            <input type="hidden" v-model="user.profileImage">
                            <div class="p-image">
                              <div class="position-relative">
                                <i class="ri-pencil-line upload-button"></i>
                                <b-form-file class="h-100 position-absolute" accept="image/*" style="opacity: 0;" @change="previewImage"></b-form-file>
                              </div>
                            </div>
                          </div>
                        </b-col>
                      </b-form-group>
                      <b-form-group class="col-sm-12" label="Bio:">
                          <b-form-textarea name="bio" v-model="user.bio" style="line-height: 22px;" rows="5">
                          </b-form-textarea>
                        </b-form-group>

                      <b-row align-v="center">
                        <div class="col-md-12 mt-3">
                          <h4>Doctor Info:</h4>
                        </div>
                        <b-form-group class="col-md-6" label="First Name" label-for="first_name">
                          <ValidationProvider name="First name" rules="required" v-slot="{ errors }">
                            <b-form-input v-model="user.first_name" type="text" placeholder="First Name" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                              <span>{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </b-form-group>

                        <b-form-group class="col-md-6" label="Last Name" label-for="last_name">
                          <ValidationProvider name="Last name" rules="required" v-slot="{ errors }">
                            <b-form-input v-model="user.last_name" type="text" placeholder="Last Name" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                              <span>{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </b-form-group>

                        <b-form-group class="col-md-6" label="Username" label-for="username">
                          <ValidationProvider name="User name" rules="required" v-slot="{ errors }">
                            <b-form-input v-model="user.username" type="text" placeholder="Username" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                              <span>{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </b-form-group>

                        <b-form-group class="col-md-6" label="Phone" label-for="phone_number">
                          <ValidationProvider name="User name" rules="required" v-slot="{ errors }">
                            <b-form-input v-model="user.phone_number" type="text" placeholder="Phone" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                              <span>{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </b-form-group>

                        <b-form-group class="col-md-6" label="Practice Phone Number" label-for="practice_number_phone">
                          <ValidationProvider name="User name" rules="required" v-slot="{ errors }">
                            <b-form-input v-model="user.practice_number_phone" type="text" placeholder="Practice phone number" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                              <span>{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </b-form-group>

                        <div class="col-md-12 mt-3">
                          <h4>Doctor Credentials:</h4>
                        </div>

                        <b-form-group class="col-md-6" label="Practice number" label-for="practice_number">
                          <b-form-input v-model="user.practice_number" type="text" placeholder="Practice number"></b-form-input>
                        </b-form-group>

                        <b-form-group class="col-md-6" label="Experience" label-for="experience">
                          <b-form-input v-model="user.experience" type="text" placeholder="Experience"></b-form-input>
                        </b-form-group>

                        <b-form-group class="col-md-6" label="Department" label-for="department">
                          <b-form-input v-model="user.department" type="text" placeholder="Department"></b-form-input>
                        </b-form-group>

                        <b-form-group class="col-md-6" label="Professional registration number" label-for="professional_registration_number">
                          <b-form-input v-model="user.professional_registration_number" type="text" placeholder="Registration number"></b-form-input>
                        </b-form-group>

                        <div class="col-md-12  mt-3">
                          <h4>Address Info:</h4>
                        </div>
                        <b-form-group class="col-md-6" label="City" label-for="city">
                          <b-form-input v-model="user.city" type="text" placeholder="City"></b-form-input>
                        </b-form-group>
                        <b-form-group class="col-md-6" label="Address" label-for="address">
                          <b-form-input v-model="user.address" type="text" placeholder="address"></b-form-input>
                        </b-form-group>
                        <b-form-group class="col-md-6" label="Postal code" label-for="postal_code">
                          <b-form-input v-model="user.postal_code" type="text" placeholder="postal_code"></b-form-input>
                        </b-form-group>

                        <b-form-group class="col-sm-6" label-for="profile_status" label="Profile activation status">
                          <b-form-select plain v-model="user.profile_status" :options="profile_status">
                          </b-form-select>
                        </b-form-group>

                        <b-form-group class="col-sm-6" label-for="accept_zlto" label="Accept zlto">
                          <b-form-select plain v-model="user.accept_zlto" :options="zlto" >
                          </b-form-select>
                        </b-form-group>
                      </b-row>

                    <div class="col-md-12  my-3">
                      <h4>Account Status:</h4>
                    </div>

                    <b-row>

                      <b-form-group class="col-sm-8" label-for="email_status" label="Email Verification status">
                        <b-form-select plain v-model="user.email_verified" :options="email_status">
                        </b-form-select>
                      </b-form-group>
                      <b-form-group class="col-sm-4" label-for="email_status" label="Update Verify Email Status">
                        <b-button @click.prevent="handleUpdateEmailVerified()" type="button" variant="success" class="mr-2 w-75" >Update User Account Email</b-button>
                      </b-form-group>

                      <b-form-group class="col-sm-8" label-for="document_status" label="Documents Verification status">
                        <b-form-select plain v-model="user.documents_verified" :options="documents_status">
                        </b-form-select>
                      </b-form-group>
                      <b-form-group class="col-sm-4" label-for="email_status" label="Update Verified Upload Documents">
                        <b-button @click.prevent="handleUpdateDocumentsVerified()" type="button" variant="success" class="mr-2 w-75" >Verify Uploaded Documents</b-button>
                      </b-form-group>

                      <b-form-group class="col-sm-12" label-for="signature_status" label="Signature status">
                        <b-form-select  disabled plain v-model="user.signature_verified" :options="signature_status">
                        </b-form-select>
                      </b-form-group>

                    </b-row>

                      <b-button type="button" variant="primary" class="mr-2" @click="handleUpdate">Submit</b-button>
                      <b-button type="reset" variant="none" class="iq-bg-danger">Cancel</b-button>
                  </template>
                </iq-card>
              </tab-content-item>
              <tab-content-item :active="false" id="manage-documents">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Documents</h4>
                  </template>
                  <template v-slot:body>
                      <b-row>
                        <b-col sm="6">
                          <b-card title="CV" class="iq-mb-3">
                            <input type="file" @change="uploadCv" ref="cvFile" style="display: none" accept="application/pdf">
                            <b-button @click="openCv" href="#" block variant="primary">Upload</b-button>
                            <div v-if="uploadedCv">
                              <b-row class="align-items-center">
                                <a :href="uploadedCv.downloadUrl" download="cvDoc.pdf" target="_blank">{{ uploadedCv.name }}</a>
                              </b-row>
                            </div>
                          </b-card>
                        </b-col>
                        <b-col sm="6">
                          <b-card title="ID Document" class="iq-mb-3">
                            <input type="file" @change="uploadIdDocument" ref="idDocumentFile" style="display: none" accept="application/pdf">
                            <b-button @click="openIdDocument" href="#" block variant="primary">Upload</b-button>
                            <div v-if="uploadedIdDocument">
                              <b-row class="align-items-center">
                                <a :href="uploadedIdDocument.downloadUrl" download="idDoc.pdf" target="_blank">{{ uploadedIdDocument.name }}</a>
                              </b-row>
                            </div>
                          </b-card>
                        </b-col>
                      </b-row>
                    <b-row>
                      <b-col sm="6">
                        <b-card title="Proof of Bank Account" class="iq-mb-3">
                          <input type="file" @change="uploadProofBank" ref="proofOfBankFile" style="display: none" accept="application/pdf">
                          <b-button @click="openProofbankAccount" href="#" block variant="primary">Upload</b-button>
                          <div v-if="uploadedProofBank">
                            <b-row class="align-items-center">
                              <a :href="uploadedProofBank.downloadUrl" download="proofOfBank.pdf" target="_blank">{{ uploadedProofBank.name }}</a>
                            </b-row>
                          </div>
                        </b-card>
                      </b-col>
                      <b-col sm="6">
                        <b-card title="HSCA Certificate or Card" class="iq-mb-3">
                          <input type="file" @change="uploadHsca" ref="hscaFile" style="display: none" accept="application/pdf">
                          <b-button @click="openHsca" href="#" block variant="primary">Upload</b-button>
                          <div v-if="uploadedhsca">
                            <b-row class="align-items-center">
                              <a :href="uploadedhsca.downloadUrl" download="hsca.pdf" target="_blank">{{ uploadedhsca.name }}</a>
                            </b-row>
                          </div>  </b-card>
                      </b-col>
                    </b-row>
                  </template>
                </iq-card>
              </tab-content-item>

              <tab-content-item :active="false" id="manage-password">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Change Password</h4>
                  </template>
                  <template v-slot:body>
                    <b-col class="col-12">
                      <b-alert :show="password_change_step === 1" variant="secondary">
                        <div class="iq-alert-text">Please enter provider new password in both new password and well as repeat password and click change password button!</div>
                      </b-alert>
                      <b-alert :show="password_change_step === 2" variant="success">
                        <div class="iq-alert-text">Provider password change <b>success</b>!</div>
                      </b-alert>
                      <b-alert :show="password_change_step === 3" variant="danger">
                        <div class="iq-alert-text">{{ error_pw_message }}</div>
                      </b-alert>
                    </b-col>
                    <b-row>
                      <b-form-group class="col-6" label="New Password:" label-for="pass">
                        <ValidationProvider name="Password" rules="confirmed:repeat_password" v-slot="{ errors }">
                          <b-form-input v-model="new_password" type="password" placeholder="Password" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                      <b-form-group class="col-6" label="Repeat Password:" label-for="rpass">
                        <ValidationProvider vid="repeat_password" name="Repeat Password" rules="required" v-slot="{ errors }">
                          <b-form-input v-model="repeat_new_password" type="password" placeholder="Repeat Password" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-row>
                    <b-row class="d-flex flex-row-reverse">
                      <b-button @click.prevent="ChangeProviderPassword" type="button" variant="primary" class="mr-2">Change Provider Password</b-button>
                    </b-row>
                  </template>
                </iq-card>
              </tab-content-item>
            </tab-content>
          </div>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
<script>
import { xray } from '@config/pluginInit'
import apiClient from '@config/apiClient'
import ProviderService from '@/services/provider'
import Vue from 'vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.use(Loading)

export default {
  name: 'Doctor-profile',
  mounted () {
    xray.index()
    apiClient.post('admin/get_provider_account', {
      provider_id: this.$route.params.id
    }).then(response => (this.user = response.data))
    this.verifyFilesExist()
  },
  beforeMount () {
    this.verifyFilesExist()
  },
  data () {
    return {
      user: {},
      password_change_step: 1,
      error_pw_message: 'Provider password change error! Please make sure password entered match and were not blank contact system Admin if problem persists',
      new_password: '',
      repeat_new_password: '',
      uploadedProofBank: null,
      uploadedhsca: null,
      uploadedCv: null,
      uploadedIdDocument: null,
      currentPassword: '',
      zlto: [
        { value: 0, text: 'Deactivate' },
        { value: 1, text: 'Activate' }
      ],
      profile_status: [
        { value: 0, text: 'In-active' },
        { value: 1, text: 'Active' }
      ],
      email_status: [
        { value: 0, text: 'Not-Verified' },
        { value: 1, text: 'Verified' }
      ],
      signature_status: [
        { value: 0, text: 'Not-Verified' },
        { value: 1, text: 'Verified' }
      ],
      documents_status: [
        { value: 0, text: 'Not Uploaded' },
        { value: 1, text: 'In Review' },
        { value: 2, text: 'Documents Verified' }
      ]
    }
  },
  methods: {
    ChangeProviderPassword: function () {
      this.password_change_step = 1
      console.log('firing change password on provider form admin')
      const loader = this.showLoader()
      if (this.new_password !== this.repeat_new_password) {
        // password do not match
        console.log('password do not match')
        console.log('password 1 : ', this.new_password)
        console.log('password 2 : ', this.repeat_new_password)
        this.password_change_step = 3
        this.error_pw_message = 'Change Password error : Password\'s do not match please check password and repeat password and make sure they match'
        loader.hide()
      }

      console.log('password length : ', this.new_password.length)
      if (this.new_password.length < 4) {
        // password to short
        console.log('password length is to short or empty')
        this.password_change_step = 3
        this.error_pw_message = 'Change Password error : Password length to short password length requirement is minimum 4 characters'
        loader.hide()
      }

      let payload = {
        provider_id: parseInt(this.user.provider_id),
        new_password: this.new_password
      }

      if (this.password_change_step !== 3) {
        console.log('firing change password routine')
        ProviderService.update_profile_password(payload)
          .then((response) => {
            this.new_password = ''
            this.repeat_new_password = ''
            loader.hide()
            console.log('password change success : ', response)
            this.password_change_step = 2
            this.error_pw_message = 'Change Password success '
          })
          .catch((error) => {
            loader.hide()
            console.log('password change error : ', error)
            this.password_change_step = 3
            this.error_pw_message = 'Change Password error : ' + error.message
          })
      }
    },
    handleUpdateDocumentsVerified: function () {
      const loader = this.showLoader()
      let payload = {
        provider_id: parseInt(this.user.provider_id),
        documents_verified: parseInt(this.user.documents_verified)
      }
      ProviderService.update_documents_verified(payload)
        .then((response) => {
          console.log('provider documents verified  service response : ', response)
          loader.hide()
        })
        .catch((error) => {
          console.log('provider documents verified  update error : ', error)
          loader.hide()
        })
    },
    handleUpdateEmailVerified: function () {
      const loader = this.showLoader()
      let payload = {
        provider_id: parseInt(this.user.provider_id),
        email_verified: parseInt(this.user.email_verified)
      }
      ProviderService.update_email_verified(payload)
        .then((response) => {
          console.log('provider email verified  service response : ', response)
          loader.hide()
        })
        .catch((error) => {
          console.log('provider email verified  update error : ', error)
          loader.hide()
        })
    },
    previewImage: function (event) {
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.user.profile_image = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    openProofbankAccount: function (event) {
      this.$refs.proofOfBankFile.click()
    },
    openHsca: function (event) {
      this.$refs.hscaFile.click()
    },
    openCv: function (event) {
      this.$refs.cvFile.click()
    },
    openIdDocument: function (event) {
      this.$refs.idDocumentFile.click()
    },
    verifyFilesExist: function () {
      const fileTypes = ['proof_of_bank_account', 'id_document', 'hsca', 'cv']
      fileTypes.forEach(ft => {
        const loader = this.showLoader()
        apiClient.get(`/admin/check/${this.$route.params.id}/${ft}/exists`).then(resp => {
          switch (ft) {
            case 'proof_of_bank_account':
              console.log('the type', ft, resp.data)
              this.uploadedProofBank = { name: resp.data.fileGeneratedName, downloadUrl: resp.data.downloadUrl }
              loader.hide()
              break
            case 'cv':
              console.log('the type', ft, resp.data)
              this.uploadedCv = { name: resp.data.fileGeneratedName, downloadUrl: resp.data.downloadUrl }
              loader.hide()
              break
            case 'id_document':
              console.log('the type', ft, resp.data)
              this.uploadedIdDocument = { name: resp.data.fileGeneratedName, downloadUrl: resp.data.downloadUrl }
              loader.hide()
              break
            case 'hsca':
              console.log('the type', ft, resp.data)
              this.uploadedhsca = { name: resp.data.fileGeneratedName, downloadUrl: resp.data.downloadUrl }
              loader.hide()
              break
          }
        })
      })
    },
    uploadProofBank: function (event) {
      const loader = this.showLoader()
      const file = event.target.files[0]
      const formData = new FormData()
      formData.append('file', file)
      apiClient.post(`/admin/upload/proof_of_bank_account/${this.$route.params.id}`, formData)
        .then(response => {
          console.log('File uploaded successfully:', response.data)
          this.uploadedProofBank = { name: response.data.fileGeneratedName }
          loader.hide()
        })
        .catch(error => {
          console.error('Error uploading file:', error)
          loader.hide()
        })
    },
    uploadHsca: function (event) {
      const loader = this.showLoader()
      const file = event.target.files[0]
      const formData = new FormData()
      formData.append('file', file)
      apiClient.post(`/admin/upload/hsca/${this.$route.params.id}`, formData)
        .then(response => {
          console.log('File uploaded successfully:', response.data)
          this.uploadedhsca = { name: response.data.fileGeneratedName }
          loader.hide()
        })
        .catch(error => {
          console.error('Error uploading file:', error)
          loader.hide()
        })
    },
    uploadCv: function (event) {
      const loader = this.showLoader()
      const file = event.target.files[0]
      this.uploadedCv = file
      const formData = new FormData()
      formData.append('file', file)
      apiClient.post(`/admin/upload/cv/${this.$route.params.id}`, formData)
        .then(response => {
          console.log('File uploaded successfully:', response.data)
          this.uploadedCv = { name: response.data.fileGeneratedName }
          loader.hide()
        })
        .catch(error => {
          console.error('Error uploading file:', error)
          loader.hide()
        })
    },
    uploadIdDocument: function (event) {
      const loader = this.showLoader()
      const file = event.target.files[0]
      this.uploadedIdDocument = file
      const formData = new FormData()
      formData.append('file', file)
      apiClient.post(`/admin/upload/id_document/${this.$route.params.id}`, formData)
        .then(response => {
          console.log('File uploaded successfully:', response.data)
          this.uploadedIdDocument = { name: response.data.fileGeneratedName }
          loader.hide()
        })
        .catch(error => {
          console.error('Error uploading file:', error)
          loader.hide()
        })
    },
    changePassword () {
      const loader = this.showLoader()
      apiClient.post('provider/reset_password', {
        provider_id: this.$route.params.id,
        new_password: this.user.password
      }).then(response => {
        loader.hide()
        console.log('>>>', response)
      }).catch(e => {
        loader.hide()
      })
    },
    showLoader () {
      return this.$loading.show({
        isFullPage: true,
        canCancel: true,
        onCancel: this.onCancel
      })
    },
    handleUpdate () {
      const loader = this.showLoader()
      apiClient.post('admin/update_provider_account', {
        bio: this.user.bio || 'None',
        provider_id: this.$route.params.id,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        phone_number: this.user.phone_number,
        email: this.user.email,
        address: this.user.address,
        city: this.user.city,
        postal_code: this.user.postal_code,
        professional_registration_number: this.user.professional_registration_number,
        department: this.user.department,
        practice_number: this.user.practice_number,
        practice_number_phone: this.user.practice_number_phone || 'None',
        profile_status: parseInt(this.user.profile_status),
        experience: this.user.experience,
        accept_zlto: this.user.accept_zlto
      }).then(response => {
        loader.hide()
        console.log('>>>', response)
      }).catch(e => {
        loader.hide()
      })
    }
  }
}
</script>
