<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <b-row>
          <b-col sm="12">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="">
              <template v-slot:headerTitle>
                <h4 class="card-title">Admins</h4>
              </template>
              <template v-slot:headerAction>
                <div class="iq-card-header-toolbar d-flex align-items-center">
                  <b-button to="/sign-up" size="small" variant="primary">Add Admin</b-button>
                </div>
              </template>
              <template v-slot:body>
                <div class="table-responsive">
                  <table class="table mb-0 table-borderless">
                    <thead>
                    <tr>
                      <th scope="col">Avatar</th>
                      <th scope="col">Username </th>
                      <th scope="col">First Name </th>
                      <th scope="col">Last Name </th>
                      <th scope="col">Account Type</th>
                      <th scope="col">Last Update</th>
                      <th scope="col">Date Created</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row, index) in admin_user" :key="'atr_'+index">
                      <td class="text-center"><img class="rounded-circle img-fluid avatar-40" src="../../assets/images/user/01.jpg" alt="profile"></td>
                      <td>{{ row['admin_username']  }}</td>
                      <td>{{  row['first_name'] || 'Not Set' }}</td>
                      <td>{{  row['last_name'] || 'Not Set' }}</td>
                      <td><i class="ri-file-pdf-line font-size-16 text-danger"></i> {{ row['admin_type'] ? 'Admin' : 'Viewer'}}</td>
                      <td>{{ $date(row['updated_at']).format('MMM D, YYYY')}}</td>
                      <td>{{  $date(row['created_at']).format('MMM D, YYYY') }}</td>
                    </tr>

                    </tbody>
                  </table>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="12" >
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Recent Activity</h4>
              </template>
              <template v-slot:headerAction>
                <div class="dropdown">
                  <span class="dropdown-toggle text-primary" id="dropdownMenuButton4" data-toggle="dropdown">
                    View All
                  </span>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton4">
                    <a class="dropdown-item" href="#"><i class="ri-eye-fill mr-2"></i>View</a>
                    <a class="dropdown-item" href="#"><i class="ri-delete-bin-6-fill mr-2"></i>Delete</a>
                    <a class="dropdown-item" href="#"><i class="ri-pencil-fill mr-2"></i>Edit</a>
                    <a class="dropdown-item" href="#"><i class="ri-printer-fill mr-2"></i>Print</a>
                    <a class="dropdown-item" href="#"><i class="ri-file-download-fill mr-2"></i>Download</a>
                  </div>
                </div>
              </template>
              <template v-slot:body>
                <ul class="iq-timeline">
                  <li v-for="item in admin_user" :key="'irow_'+item['admin_id']">
                    <div class="timeline-dots-fill"></div>
                    <h6 class="float-left mb-2 text-dark"><i class="ri-user-fill mr-3"></i>{{ item['created_at'] | moment("from", "now") }}</h6>
                    <small class="float-right mt-1">Active</small>
                    <div class="d-inline-block w-100">
                      <p>New admin registered with username {{ item['admin_username'] }} </p>
                    </div>
                  </li>
                </ul>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import IqCard from '../../components/xray/cards/iq-card'
import AdminService from '../../services/admin'
import Vue from 'vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.use(Loading)

export default {
  name: 'Doctors',
  components: { IqCard },
  mounted () {
    xray.index()
    this.getAdminList()
  },
  data () {
    return {
      admin_user: []
    }
  },
  methods: {
    showLoader () {
      return this.$loading.show({
        isFullPage: true,
        canCancel: true,
        onCancel: this.onCancel
      })
    },
    getAdminList () {
      const loader = this.showLoader()
      AdminService.get_all_admin_accounts().then((response) => {
        loader.hide()
        console.log('admin list response : ', response)
        // check if success then do route change after setting store
        if (response.status === 200) {
          this.admin_user = response.data
        }
      }).catch((error) => {
        console.log('admin list error : ', error)
        loader.hide()
      })
    }
  }
}
</script>
<style>
.iq-card-body{
  flex: unset;
}
</style>
