import Vue from 'vue'
import '@babel/polyfill'
import 'mutationobserver-shim'
import './Utils/fliter'
import App from './App.vue'
import router from './router'
import store from './store'
import Raphael from 'raphael/raphael'
import './plugins'
import './registerServiceWorker'
import i18n from './i18n'
import VueDayjs from 'vue-dayjs-plugin'
import vuetify from './plugins/vuetify'
global.Raphael = Raphael
Vue.config.productionTip = false
Vue.use(VueDayjs)
Vue.use(require('vue-moment'))
let vm = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')

window.vm = vm
